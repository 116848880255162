






















































































































































































































































































































































































































































































































































































































































































































































































































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import StandardMessage from '@/components/messages/StandardMessage.vue';
import ReplyMessage from '@/components/messages/ReplyMessage.vue';
import ImageMessage from '@/components/messages/ImageMessage.vue';
import AttachmentMessage from './messages/AttachmentMessage.vue';
import SystemMessage from '@/components/messages/SystemMessage.vue';
import PersonAddedToChatMessage from '@/components/messages/PersonAddedToChatMessage.vue';
import RequestPersonalDataMessage from '@/components/messages/RequestPersonalDataMessage.vue';
import PersonalDataResultMessage from '@/components/messages/PersonalDataResultMessage.vue';
import LocationMessage from '@/components/messages/LocationMessage.vue';
import RequestAppointment from '@/components/messages/RequestAppointment.vue';
import ChatInfo from '@/components/ChatInfo.vue';
import NoMessages from '@/components/NoMessages.vue';
import BusinessCardMessage from '@/components/messages/BusinessCardMessage.vue';
import GroupAvatar from '@/components/GroupAvatar.vue';
import ForwardMessage from '@/components/messages/ForwardMessage.vue';
import DialogAddChatMembers from '@/components/chats/DialogAddChatMembers.vue';
import {customersStore} from '@/store/modules/customers/CustomersStore';
import ContactsList from '@/components/ContactsList.vue';
import GoogleMap from '@/components/maps/GoogleMap.vue';
import constants from '@/common/constants';
import {
  compareMembersFn,
  copyTextToClipboard,
  downloadFile,
  firstWord,
  twoChars,
  getFirstName,
  getCustomImageUrl
} from '@/utils/helpers';
import {
  ACTION_MESSAGE_COPY,
  ACTION_MESSAGE_DELETE,
  ACTION_MESSAGE_EDIT,
  ACTION_MESSAGE_FORWARD,
  ACTION_MESSAGE_MORE,
  ACTION_MESSAGE_PIN_NOTE,
  ACTION_MESSAGE_REPLY,
  ACTION_MESSAGE_DOWNLOAD
} from '@/common/context-menu-actions';
import {Action, Getter, Mutation} from 'vuex-class';
import {Timestamp, Unsubscribe, getDoc} from 'firebase/firestore';
import DialogConfirm from '@/components/DialogConfirm.vue';

import DialogForwardCase from '@/components/DialogForwardCase.vue';
import {applicationStore} from '@/store/modules/application';
import SearchView from '@/components/SearchView.vue';
import {functions} from '@/plugins/firebase.init';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {chatStore} from '@/store/modules/chat/ChatStore';
import DialogDeleteMessages from '@/components/DialogDeleteMessages.vue';
import {profileStore} from '@/store/modules/profile';
import DialogForwardMessage from '@/components/chat/DialogForwardMessage.vue';
import DialogSendContact from '@/components/chat/DialogSendContact.vue';
import {videoCallStore} from '@/store/modules/video/videoCallStore';
import DialogSendLocation from '@/components/chat/DialogSendLocation.vue';
import DialogSendImage from '@/components/chat/DialogSendImage.vue';
import DialogScheduleAppointment from '@/components/directory/customer/DialogScheduleAppointment.vue';
import DialogDetailSchedule from '@/components/chats/DialogDetailSchedule.vue';
import Notifications from '@/components/mixins/Notifications';
import {mixins} from 'vue-class-component';
import {requestsStore} from '@/store/modules/requests/RequestsStore';
import ContactInfo2 from '@/components/ContactInfo2.vue';
import CustomerProfile from '@/components/business/customers/CustomerProfile.vue';
import {notificationsStore} from '@/store/modules/notifications';
import ToolTip from '@/components/custom/ToolTip.vue'
import {tourStore} from '@/store/modules/tour';
import {httpsCallable} from 'firebase/functions';
import {AssociateAccount, MessageUI} from '@/domain/model/types';
import {user} from '@/data/firebase';
import {directoryStore} from '@/store/modules/directory/directoryStore';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate'
]);

@Component({
  name: 'chat-screen2',
  components: {
    CustomerProfile,
    ContactInfo2,
    DialogConfirm,
    DialogSendLocation,
    DialogSendImage,
    DialogSendContact,
    DialogForwardMessage,
    DialogDeleteMessages,
    DialogScheduleAppointment,
    DialogDetailSchedule,
    AvatarWithStatus,
    SearchView,
    StandardMessage,
    ReplyMessage,
    ImageMessage,
    AttachmentMessage,
    SystemMessage,
    PersonAddedToChatMessage,
    RequestPersonalDataMessage,
    PersonalDataResultMessage,
    LocationMessage,
    ChatInfo,
    NoMessages,
    BusinessCardMessage,
    GroupAvatar,
    ForwardMessage,
    DialogAddChatMembers,
    ContactsList,
    GoogleMap,
    DialogForwardCase,
    RequestAppointment,
    ToolTip
  }
})
export default class ChatScreen2 extends mixins(Vue, Notifications) {
  @Prop() chatId?: string;
  @Prop() type?: string;
  @Prop() subtype?: string;
  @Prop({default: false}) newChat?: boolean;

  viewContactId: string | null = null
  viewCustomerId: string | null = null

  windows: any = {
    chat: 0
  };

  activeWindow: number = this.windows.chat;
  chatMessageText: string = '';
  chatMessageLoading: boolean = false;
  messageToReplay: any = null;
  messageToEdit: any = null;
  messageToDelete: any = null;
  moreOption: boolean = false;
  toolbarTitle: string = '';
  drawerChatInfo: boolean = false;
  unsubscribeMessages: Unsubscribe | null = null;
  unsubscribeTextSession: Unsubscribe | null = null;
  unsubscribeCaseNotes: Unsubscribe | null = null;
  unsubscribeCustomerNotes: Unsubscribe | null = null;
  error: any = {message: null};
  showError: boolean = false;
  personalOnly: boolean = false;
  showDialogSendContact: boolean = false;
  disableRequestControls: boolean = false;
  isCaseInfoActive: boolean = false;
  acceptRequestSelected: boolean = false;
  rejectRequestSelected: boolean = false;
  blockCustomerSelected: boolean = false;
  forwardRequestSelected: boolean = false;
  forwardTrigger: boolean = true
  selectedConversation: any = null;
  dialogForwardMessage: boolean = false;
  dialogConfirmAction: boolean = false;
  dialogConfirmActionTitle: string = '';
  dialogConfirmActionMessage: string = '';
  dialogConfirmActionCancelShow: boolean = true
  onDialogConfirmActionPositiveCallback: any;
  onDialogConfirmCancel: any = null;
  confirm: string = ''
  //todo: refactore
  textColor: string = ''
  //todo: refactore
  btnColor: string = 'error'
  showMap: boolean = false;
  fullscreenMap: boolean = false
  location: any = null;
  chatInfoWindow: string | null = 'info';
  imageTypeMessage: any = null;
  imageTypeMessageForward: boolean = false;
  showImage: boolean = false;

  titleForwardCaseDialog: string | null = '';
  showForwardCaseDialog: boolean = false;
  showDeleteMessagesDialog: boolean = false;
  showForwardMessageDialog: boolean = false;
  showDialogScheduleAnAppointment: boolean = false
  showDialogDetailSchedule: boolean = false;

  showAddChatMembersDialog: boolean = false
  createGroup: boolean = false

  srcMessages: any[] = [];

  audioSelected: boolean = false;
  file: any = null
  _files: any = null

  loading: boolean = false;
  isSMS: boolean = false;
  chatTypeVisible: boolean = false;
  isDecisionMadeForSMSWarning: boolean = false;
  isDecisionMadeForAppWarning: boolean = false;

  adminPrevilege: boolean = false;
  adminContacts: any = null;
  chatTypes: any = [
  {
    text: 'In App',
    value: false
  },
  {
    text: 'SMS',
    value: true
  }];

  phoneNumbers:any = [];

  @Getter selectedTextSession;
  @Getter messages;
  @Getter selectedLocation;
  @Getter messagesLoaded;
  @Getter isLoadingMessages;
  @Getter showFullConversation;
  @Getter getSelectedAppointment;

  @Mutation setFullConversation;
  @Mutation setSelectedCustomer;

  @Action closeConversation;
  @Action loadChat;
  @Action loadMessages;
  @Action sendImageMessage;
  @Action sendAttachmentMessage;
  @Action sendReplyMessage;
  @Action editMessage;
  @Action forwardMessage;
  @Action sendTextMessage;
  @Action sendSMS;
  @Action forwardCase;
  @Action rejectRequest;
  @Action acceptRequest;
  @Action caseClose;
  @Action sendContact;
  @Action requestPersonalData;
  @Action resetSelectedLocation;
  @Action sendLocationMessage;
  @Action resetMessagesLoaded;
  @Action unblockContact;
  @Action blockContact;
  @Action createInnerChat;
  @Action requestAppointment;
  @Action addChatMembers;

  @Watch('chatMessageText')
  async onChatMessageChanged(after, before) {
    if (!before && !!after) {
      await chatStore.updateTyping({chatId: this.chatId, typing: true})
      return
    }
    if (!!before && !after) {
      await chatStore.updateTyping({chatId: this.chatId, typing: false})
    }
  }

  @Watch('messagesLoaded')
  onMessageLoadedEvent(value, oldValue) {
    if (!value) {
      return;
    }
    const callback = (context) => {
      return function() {
        const messagesLayout: any = context.$refs.messagesLayout;
        if (messagesLayout) {
          messagesLayout.scrollTop = messagesLayout.scrollHeight;
          messagesLayout.style.visibility = 'visible';
          messagesLayout.style.scrollBehavior = 'auto';
        }
        context.resetMessagesLoaded();
      }
    };
    setTimeout(callback(this), 250);
  }

  @Watch('selectedTextSession')
  async onSelectedChatChanged(newChat, oldChat) {
    if (newChat?.id === oldChat?.id) {
      return
    }
    let unreadCount = 0
    if (!!newChat?.unread && newChat.unread[this.userId!] > 0) {
      unreadCount = newChat.unread[this.userId!]
    }
    if (unreadCount > 0) {
      //todo: replace with Go API
      const resetCounter = httpsCallable(functions, 'apiDbTextSessionsResetCounter')
      try {
        await resetCounter({
          userId: this.userId,
          textSessionId: newChat.id
        })
      } catch (err) {
        console.error(`apiDbTextSessionsResetCounter: ${err}`)
      }
    }
  }

  @Watch('undoProcessed')
  onChangUndoProcessed() {
    this.switchRequestControl()
  }

  get key() {
    return `${this.type}:${this.subtype}`
  }

  get messageItems(): MessageUI[] {
    if (this.showFullConversation) {
      return this.messages
    }
    const millis = this.caseOpenDate?.toMillis() || 0;
    return this.messages.filter((message: MessageUI) => message.createdAtMillis >= millis)
  }

  get t2bUser() {
    return profileStore.t2bUser;
  }

  get businessId() {
    return applicationStore.businessId;
  }

  get withInput() {
    return this.isChatMember && (this.isRequest || this.isAccepted || this.isRejected || this.isInnerChat || !!this.newChat);
  }

  get messagesViewStyle() {
    if (this.withInput) {
      return {
        maxHeight: '83vh',
        height: '83vh'
      };
    } else {
      return {
        maxHeight: '94vh',
        height: '94vh'
      };
    }
  }

  get userId() {
    return this.t2bUser?.id;
  }

  get userName() {
    return this.t2bUser?.fullName;
  }

  get denseInput() {
    return this.messageSelected || this.audioSelected;
  }

  get customer() {
    return this.selectedTextSession?.customer
  }

  get messageSelected() {
    return this.messageToReplay || this.messageToEdit || this.messageToForward;
  }

  get selectedMessages() {
    return this.messageItems.filter(message => message.selected);
  }

  get selectedMessagesCount() {
    return this.selectedMessages.length;
  }

  get isFirstSMS() {    
    return this.messages.filter(message => message?.data?.isSMS == true).length > 0 ? false : true;        
  }

  // todo: revise this!!! BAW-975
  // hide -> forward case, send contact and notification 
  get isHideCustom() { 
    const routName: string = this.$route.name!
    const routType: any = this.$route.query?.subtype

    if(routName === 'inbox-request' && routType === 'all'){
      return true; // Requests > Inbox > All  
    }
    else if(routName === 'rejected-request' && routType === 'all'){
      return true; // Requests > Rejected > All
    }
    else if(routName === 'active-chat' && routType === 'all'){
      return true; // Chats > All > External
    }  
    else{
      return false
    }  
  }
  
  get onlyInboxRequestAll() { 
    const routName: string = this.$route.name!
    const routType: any = this.$route.query?.subtype

    if((routName === 'inbox-request') && this.selectedTextSession.case.forwardedDate == undefined && routType === 'all'){
      return true; // Requests > Inbox > All  
    }
    else{
      return false
    }  
  }

  get isActiveChat() {
    return this.selectedTextSession && this.selectedTextSession.type === 4;
  }

  get isInnerChat() {
    return this.selectedTextSession && this.selectedTextSession.type === 3;
  }

  get isAccepted() {
    return (
        this.selectedTextSession &&
        this.selectedTextSession.case &&
        this.selectedTextSession.case.status === 2
    );
  }

  get isRequest() {
    return this.selectedTextSession?.case?.status === 1;
  }

  get isRejected() {
    return this.selectedTextSession?.case?.status === 3;
  }

  get isGroupChat() {
    return this.selectedTextSession?.subtype > 2;
  }

  get isChatMember() {
    return !!this.selectedTextSession?.memberIDs?.includes(this.userId)
  }

  get associatePrevilage() {
    return this.adminPrevilege
  }

  get isAdmin() {
    return profileStore.isAdmin
  }

  get isCustomerChat() {
    return this.selectedTextSession?.customer instanceof Object
  }

  get isBlockedUser() {
    return Object.values(this.selectedTextSession?.blockList || {}).includes(this.userId!)
  }

  get hasBlockedUser() {
    return !!(this.selectedTextSession?.blockList || {})[this.userId!]
  }

  get isGroup(): boolean {
    return this.selectedTextSession && this.selectedTextSession.subtype > 2;
  }

  get extendedGroupChat() {
    return !!this.selectedTextSession && this.selectedTextSession.subtype === 3;
  }

  get title() {
    return this.selectedTextSession && this.selectedTextSession.title
  }

  get membersCount() {
    return this.selectedTextSession.memberIDs.length || 0;
  }

  get chatTitle() {
    if (!!this.title) {
      return this.title
    }

    //members count > 2, then consider as group chat
    if(this.isGroup || this.selectedTextSession.memberIDs.length > 2){
      const custId = this.selectedTextSession.customer?.id
      let titleText = this.selectedTextSession.customer?.name || ''  
      
      //#BAW-983 - customer name + other associates first name    
      if(titleText){
        const memberUids = Object.keys(this.selectedTextSession?.members || {}).sort();
        memberUids.forEach(uid => {
          const member = this.selectedTextSession.members[uid];        
          if (member.uid != custId) {          
            titleText =  titleText + ', ' + getFirstName(member.name);
          }        
        });
      }else{
        return Object.values(this.selectedTextSession.members).map((item: any) => item.name).sort().join(', ')
      }

      return titleText      
    }

    if (this.isActiveChat) {
      return this.selectedTextSession?.customer && this.selectedTextSession.customer.name
    }
    if (this.extendedGroupChat) {
      return Object.values(this.selectedTextSession.members).map((item: any) => item.name).sort().join(', ')
    }
    return this.selectedTextSession?.from.uid === this.userId
        ? this.selectedTextSession?.to.name
        : this.selectedTextSession?.from.name
  }

  get isSingleCustomer() {    
    const customerList: any =  Object.values(this.selectedTextSession?.members).filter(
      (item: any) => (item.type == 2)
    );

    if(customerList.length == 1)  {
      return true;
    }      
    return false;        
  } 

  get allowShowDOB() {
    return !!this.selectedTextSession?.customer?.permissions?.viewDOB
  }

  get dob() {
    return this.isActiveChat && this.allowShowDOB ? this.selectedTextSession?.customer?.dob : null
  }

  get checkLastPermissionRequest() {
    const lastMassage = this.messageItems.slice(-1)[0]
    return lastMassage.data.type === constants.MESSAGE_TYPE_REQUEST_PERSONAL_DATA
        || lastMassage.data.type === constants.MESSAGE_TYPE_PERSONA_DATA_RESULT
  }

  get checkPermissionRequest() {
    return this.isActiveChat && !(this.customer.permissions?.viewEmail
        || this.customer.permissions?.viewPhone
        || this.customer.permissions?.viewDOB
    )
  }

  get imageUrl() {
    if (!this.selectedTextSession) {
      return null
    }
    if (this.isActiveChat) {
      const customer = this.selectedTextSession.customer;
      const photoUrl = customer && customer.photoUrl;
      return photoUrl && photoUrl.normal
    }
    const fromId = this.selectedTextSession.from && this.selectedTextSession.from.uid;
    const toId = this.selectedTextSession.to && this.selectedTextSession.to.uid;
    const uid = fromId === this.userId ? toId : fromId
    const member = this.selectedTextSession.members && this.selectedTextSession.members[uid];
    return (member && member.photoUrl) ? member.photoUrl.normal : null;
  }

  get imagesUrls() {
    if (!this.selectedTextSession) {
      return null
    }
    return Object.values(this.selectedTextSession.members)
        .sort(compareMembersFn)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.normal);
  }

  get imagesUrlsCnt() {
    if (!this.selectedTextSession) {
      return false
    }

    let imageUrl = Object.values(this.selectedTextSession.members)
        .sort(compareMembersFn)
        .filter((item: any) => item && !!item.photoUrl)
        .map((item: any) => item.photoUrl.normal);

    return imageUrl.length > 0;
  }

  get hasHistory() {
    return this.selectedTextSession
        && this.selectedTextSession.cases > 1
  }

  get caseOpenDate() {
    return this.selectedTextSession
        && this.selectedTextSession.case
        && this.selectedTextSession.case.openedDate
  }

  get firstMessageCreatedDate() {
    return this.messages[0].data.createdDate
  }

  get dispatchForCode() {
    return function(event, callback) {
      let code;

      if (event.key !== undefined) {
        code = event.key;
      } else if (event.keyIdentifier !== undefined) {
        code = event.keyIdentifier;
      } else if (event.keyCode !== undefined) {
        code = event.keyCode;
      }
      callback(code);
    };
  }

  get canJoinVideoCall() {
    return !!this.selectedTextSession?.videoCall
  }

  get messageToForward() {
    return chatStore.messageToForward
  }

  get undo() {
    return notificationsStore.undo
  }

  get undoProcessed() {
    return notificationsStore.undoProcessed
  }

  get foreignMessage() {
    return !!this.selectedMessages?.find((message) => message.data.sender.uid !== this.t2bUser?.id)
  }

  get isUserPresent() {
    const presence = this.selectedTextSession.presence;
    return !!presence && presence.hasOwnProperty(this.userId!!)
  }

  get smsSenderId() {
    return applicationStore.smsSenderId
  }

  get tourNumber() {
    return tourStore.tourNumber
  }

  get phoneNumber() {
    let phoneNumber = ''

    if(this.customer && this.customer.phoneNumber){
	    phoneNumber = this.customer.phoneNumber;
	    phoneNumber = phoneNumber.replace(/[^\d]/g, "");
    }
    return phoneNumber
  }

  private async getPhoneNumbers(){    
    if(this.membersCount > 2){
      const ids: any =  Object.values(this.selectedTextSession?.members).filter(
        (item: any) => (item.type == 2 && item?.isRemoved != true)).map((item:any) => item.uid
      );
      this.phoneNumbers = await customersStore.getSubscribersPhone(ids);      
    }else{
      this.phoneNumbers = [];
    }    
  }

  get sender() {
      return {
          contactId: this.selectedTextSession.associate.id,
          contactName: this.selectedTextSession.associate.name
      }
  }

  get groupChatTitle() {
      var chatTitle = ""

      const from = this.selectedTextSession.from;
      if (from) {
        chatTitle = from.name
      }
      const to = this.selectedTextSession.to;
      if (to) {
        chatTitle = chatTitle + ", " + to.name
      }
      if(chatTitle != ""){
        chatTitle = chatTitle + ", " + this.userName
      }

      return chatTitle
  }

  onAudioFileSelected(event) {
    console.log(`onAudioRecorderEvent => ${JSON.stringify(event)}`);
    const file = event.target.files[0];
    const fileSize: number = +((file.size / 1024) / 1024).toFixed(4);
    console.log(`onAudioRecorderEvent => file.size = ${JSON.stringify(fileSize)} Mb`);
    const _URL = window.URL || window.webkitURL;
    const url = _URL.createObjectURL(file);
    // Do something with the audio file.
    const player: HTMLAudioElement = this.$refs.audioPlayer as HTMLAudioElement;
    player.src = url;
    console.log(`onAudioRecorderEvent => added player.src = ${url}`);
  }

  async isAdminPrevilege(){

    this.adminContacts = directoryStore.allContacts.filter((value => value.associate?.id == this.userId));

    let isMember = !!this.selectedTextSession?.memberIDs?.includes(this.userId)
    if(isMember) {
      this.adminPrevilege = true
    }else {
      if(this.selectedTextSession != null && this.selectedTextSession.associate != null) {
        let uid = this.selectedTextSession.associate.uid
        let userSnapshot = await getDoc(user(uid));
        if (userSnapshot.exists()) {
          const data = userSnapshot.data() as AssociateAccount;
          let roles = data.roles
          if(this.adminContacts != null && this.adminContacts.length > 0){
            this.adminPrevilege = false
          }else{
            this.adminPrevilege = true
          }
        }
      }
    }
  }

  async onRecordAudio() {
    this.audioSelected = true;
    await this.initAudioPlayer();
  }

  async onStartVideoCall() {
    try {
      await videoCallStore.startVideoCall(this.selectedTextSession.id)
    } catch (err) {
      console.error(err)
    }
  }

  async onJoinVideoCall() {
    try {
      await videoCallStore.joinVideoCall({
        chatId: this.selectedTextSession?.id,
        videoCallId: this.selectedTextSession.videoCall?.id
      })
    } catch (err) {
      console.error(err)
    }
  }

  switchRequestControl() {
    if (this.chatId && this.undoProcessed.indexOf(this.chatId!) !== -1) {
      this.disableRequestControls = true
      this.rejectRequestSelected = true
    } else {
      this.disableRequestControls = false
      this.rejectRequestSelected = false
    }
  }

  closeDialog() {
    this.disableRequestControls = false;
  }

  showAvatar(index, senderUid) {
    const prevMessage = this.messageItems[index - 1];
    const prevSenderUid = prevMessage && prevMessage?.data?.sender.uid;
    const nextMessage = this.messageItems[index + 1];
    const nextSenderUid = nextMessage && nextMessage?.data?.sender.uid;
    if (!prevSenderUid && !nextSenderUid) {
      return true;
    } else {
      return nextSenderUid !== senderUid;
    }
  }

  messageKind(index, messages) {
    const prevIndex = index - 1
    const nextIndex = index + 1

    const prevMsg = messages[prevIndex]
    const nextMsg = messages[nextIndex]

    const currentUid = messages[index].data.sender.uid;
    const prevUid = prevMsg && prevMsg.data.sender.uid;
    const nextUid = nextMsg && nextMsg.data.sender.uid;

    return [currentUid === prevUid, currentUid === nextUid];
  }

  messageDbClicked(message) {
    if (this.messageToReplay !== message) {
      this.messageToReplay = message;
    } else {
      this.messageToReplay = null;
    }
  }

  async onOptionItemClicked(action, messageView) {
    const message = messageView.data;
    switch (action.type) {
      case ACTION_MESSAGE_DOWNLOAD: {
        this.downloadImage(message);
        break;
      }
      case ACTION_MESSAGE_REPLY: {
        this.messageDbClicked(message);
        break;
      }
      case ACTION_MESSAGE_COPY: {
        try {
          await copyTextToClipboard(message.text);
          this.showInfo('Message has been copied');
        } catch (err) {
          this.showIssue('Failed to copy message');
        }
        break;
      }
      case ACTION_MESSAGE_EDIT: {
        this.chatMessageText = message.text;
        this.messageToEdit = message;
        break;
      }
      case ACTION_MESSAGE_PIN_NOTE: {
        try {
          const result = await chatStore.pinMessageToNote(message);
          if (result) {
            this.showInfo('Note has been created');
          } else {
            this.showIssue('Note has been removed');
          }
        } catch (error: any) {
          this.showIssue(error.message);
        }
        break;
      }
      case ACTION_MESSAGE_FORWARD: {
        this.onForwardMessage(message)
        break;
      }
      case ACTION_MESSAGE_DELETE: {
        messageView.selected = true
        this.showDeleteMessagesDialog = true
        break;
      }
      case ACTION_MESSAGE_MORE: {
        this.moreOption = true;
        break;
      }
      default:
    }
  }

  onForwardMessage(message: any) {
    chatStore.setForwardedMessage(message)
    this.showForwardMessageDialog = true
  }

  onForwardMessages() {
    chatStore.setForwardedMessages(this.selectedMessages.map((item) => item.data))
    this.onCancelSelection()
    this.showForwardMessageDialog = true
  }

  onForwardMessageToChat(chatId: string) {
    this.forwardTrigger = false
    this.$router.push({name: 'active-chat', params: {chatId}, query: {type: 'active', subtype: 'personal'}})
  }

  async onForwardMessageToContact(contactId: string) {
    try {
      const chatId = await this.createInnerChat({contactIds: [contactId]})
      await this.$router.push({name: 'inner-chat', params: {chatId}, query: {type: 'inner', subtype: 'personal'}})
    } catch (err: any) {
      this.showIssue(err.message)
    }
  }

  onCancelForwardMessage() {
    chatStore.clearForwarding()
    this.onCancelSelection()
  }

  onDeleteMessages() {
    this.showDeleteMessagesDialog = true
  }

  @Action deleteMessages

  private async deleteSelectedMessages(forAll: boolean) {
    await this.deleteMessages({
      messageIDs: this.selectedMessages?.map((message) => message.data.id),
      forAll
    })
  }

  onCancelSelection() {
    this.moreOption = false
    this.selectedMessages
        .filter((message) => message.selected)
        .forEach((message) => message.selected = false)
  }

  onCaseNotes() {
    this.chatInfoWindow = 'caseNotes';
    this.drawerChatInfo = true;
  }

  onViewPersonalData() {
    this.chatInfoWindow = 'viewPersonalData';
    this.drawerChatInfo = true;
  }

  async onShowDetailSchedule(appointId) {
    try {
      await requestsStore.loadAppointmentById(appointId);
      this.showDialogDetailSchedule = true;
    } catch (error) {
      console.log(error);
    }
  }

  onChatInfo() {
    this.chatInfoWindow = 'info';
    this.drawerChatInfo = true;
  }

  getLetters(text) {
    return twoChars(text);
  }

  trimTitle(str) {
      if (str.length > 100) {
      return str.substr(0, 100) + '...' + str.substr(str.length-50, str.length);
    }
    return str;
  }

  get typing() {
    const presence = this.selectedTextSession?.presence || {};
    const typing = Object.keys(presence)
        .filter((uid) => this.userId !== uid && presence[uid])
        .map((uid) =>
            (this.selectedTextSession.members && this.selectedTextSession.members[uid]) ?
                firstWord(this.selectedTextSession.members[uid].name) : '')
        .join(',');
    return typing ? `${typing} typing...` : this.onlineStatus;
  }

  get onlineStatus(): string {
    const memberUids = Object.keys(this.selectedTextSession?.members || {});
    const membersCount = memberUids.length;
    let onlineCount = 0;
    memberUids.forEach(uid => {
      const member = this.selectedTextSession.members[uid];
      if (member.status && member.status.online) {
        onlineCount++;
      }
    });
    return `${membersCount} members, ${onlineCount} online`;
  }

  get unreadCount(): number {
    const unread = this.selectedTextSession?.unread || {}
    return unread[this.userId!] || 0;
  }

  @Watch('isBlockedUser')
  onIsBlockedUserChanged(after: boolean, _: boolean) {
    if (after) {
      this.dialogConfirmActionTitle = `You have been blocked by ${this.chatTitle}. Do you want to close this case?`
      this.dialogConfirmActionMessage = 'This case will store in the archive'
      this.confirm = 'Yes, close'
      this.onDialogConfirmCancel = async () => {
        await chatStore.clearChatIdForType(this.key)
        await this.$router.push({name: 'active', params: {chatId: ''}, query: this.$route.query});
        this.dialogConfirmAction = false;
      }
      this.onDialogConfirmActionPositiveCallback = () => {
        this.closeCase()
      }
      this.dialogConfirmAction = true;
    } else {
      this.dialogConfirmAction = false;
      this.dialogConfirmActionTitle = ''
      this.dialogConfirmActionMessage = ''
      this.confirm = ''
      this.onDialogConfirmCancel = null
      this.onDialogConfirmActionPositiveCallback = null
    }
  }

  private forwardedMessageType(message: any) {
    return message.data?.forwardedMessage?.type
  }

  private searchMessages(term) {
    if (!term) {
      this.srcMessages = Object.assign([], this.messageItems);
      return;
    }
    const searchString = term.toLowerCase();
    const fFunc = (value) => value.text.toLowerCase().includes(searchString)
        || value.sender.toLowerCase().includes(searchString);
    this.srcMessages = this.messageItems.filter(fFunc);
  }

  // Triggered when a file is selected via the media picker.
  private onMediaFileSelected(event) {
    this.file = event.target.files[0];

    if (this.file) {
      // Clear the selection in the file picker input.
      // imageFormElement.reset();
      // Check if the file is an image.
      if (!this.file.type.match('image.*')) {
        this.error = {
          message: 'You can only send images',
          timeout: 2000
        };
        return;
      }

      this.dialogConfirmActionTitle = 'Do you want send image ?';
      this.dialogConfirmActionMessage = '';
      this.onDialogConfirmCancel = () => {
        this.file = null
      }
      this.onDialogConfirmActionPositiveCallback = async () => {
        this.dialogConfirmAction = false;
        // Check if the user is signed-in
        if (this.t2bUser) {
          const screenWindow: any = window;
          const _URL = screenWindow.URL || screenWindow.webkitURL;
          const img = new Image();
          img.onload = async () => {
            try {
              let isSmsSent = false;              
              
              // mms image in cloud
              if(this.isSMS) {
                this.loading = true;                
                const downloadUrl = await getCustomImageUrl(this.selectedTextSession.id, this.file);                
                if(downloadUrl){
                  isSmsSent = await this.smsCall(true, downloadUrl);
                }
              }

              // add image in db
              if(!this.isSMS || isSmsSent){
                await this.sendImageMessage({
                  src: img.src,
                  file: this.file,
                  width: img.width,
                  height: img.height,
                  isSMS: this.isSMS
                });
              }  
              this.file = null
              this.loading = false;
              
            } catch (error) {
              console.error(
                  'There was an error uploading a file to Cloud Storage:',
                  error
              );
            }
          };
          img.onerror = () => {
            this.showIssue(`not a valid file: ${this.file.type}`);
          };
          img.src = _URL.createObjectURL(this.file);
        }
      }
      this.confirm = 'Yes, send'
      this.dialogConfirmAction = true;
      this.loading = false;
    }
  }

  private onMediaAttachmentSelected(event) {

    let files = event.target.files
    if(files.length == 1){
      let file = event.target.files[0];
      if (file.type.match('image/*')) {
        this.onMediaFileSelected(event)
        return
      }
    }

   this._files = event.target.files
   const screenWindow: any = window;
   const _URL = screenWindow.URL || screenWindow.webkitURL;

   
   
   this.onDialogConfirmActionPositiveCallback = async (files) => {
      this.dialogConfirmAction = false;
      if (this.t2bUser) {
        try{
          if(!this.isSMS){
            for (const file of files) {
              if (file.type.match('image/*')) {
                const img = new Image();
                img.src = _URL.createObjectURL(file);
                img.onload = async () => {
                  try {
                      this.sendImageMessage({
                        src: img.src,
                        file: file,
                        width: img.width,
                        height: img.height,
                        isSMS: this.isSMS
                      });
                    
                  } catch (error) {
                    console.error(
                        'There was an error uploading a file to Cloud Storage:',
                        error
                    );
                  }
                };
                img.onerror = () => {
                  this.showIssue(`not a valid file: ${this.file.type}`);
                };
              }else{
                this.sendAttachmentMessage({
                  src: _URL.createObjectURL(file),
                  file: file,
                  isSMS: false
                });
              }
            }
          }else{
            
          }
        } catch (error) {
            console.error(
                'There was an error uploading a file to Cloud Storage:',
                error
            );
        }
      }
    }

    this.onDialogConfirmCancel = () => {
        this._files = null
    }

   for (const file of this._files) {
      console.log(`${file.name}: ${file.size} bytes`);
      
    }

    this.dialogConfirmActionTitle = this._files.length + ' files';

    this.confirm = 'Send'
    this.dialogConfirmAction = true;
    
  }



  private verifyisValidMobile(){        
      if(this.phoneNumber === ''){  
        console.error("Phone number is not available");       
        return false
      }
      else if(this.phoneNumber.length < 10){  
        console.error("Invalid phone number");       
        return false
      }
      return true
  }

  private verifyCustomerisOnline() {
    const memberUids = Object.keys(this.selectedTextSession?.members || {});
    var userisonline = false;
    memberUids.forEach(uid => {
      const member = this.selectedTextSession.members[uid];
      console.log('member.type: ' + member.type)
      if (member.type == 2 && member.status && member.status.online) {
          userisonline = true;
      }
    });
    console.log('userisonline: ' + userisonline)
    return userisonline
  }

  private warnUserBeforeSendMessage(event) {

    if(!this.isSMS && !this.verifyCustomerisOnline() && this.verifyisValidMobile()){

      if(!this.isDecisionMadeForSMSWarning) {
          this.isDecisionMadeForSMSWarning = true

          this.dialogConfirmActionTitle = 'Customer is offline.';
          this.dialogConfirmActionMessage = 'Do you want to send message as SMS?';
          this.onDialogConfirmCancel = () => {   
            this.sendMessage(event)     
          }
          this.onDialogConfirmActionPositiveCallback = async () => {
            this.dialogConfirmAction = false;
            // Check if the user is signed-in
            this.isSMS = true
            this.onChangeChatType(false)
            this.sendMessage(event)
          }
          this.confirm = 'Proceed'
          this.dialogConfirmAction = true;

          return true
      }

    }else if(this.isSMS && this.verifyCustomerisOnline()){

      if(!this.isDecisionMadeForAppWarning){
        this.isDecisionMadeForAppWarning = true

        this.dialogConfirmActionTitle = 'Customer is online. Do you want to send message as app message?';
        this.dialogConfirmActionMessage = '';
        this.onDialogConfirmCancel = () => {    
          this.sendMessage(event)     
        }
        this.onDialogConfirmActionPositiveCallback = async () => {
          this.dialogConfirmAction = false;
          // Check if the user is signed-in
          this.isSMS = false
          this.onChangeChatType(false)
          this.sendMessage(event)
        }
        this.confirm = 'Proceed'
        this.dialogConfirmAction = true;
        return true
      }
    }

    return false
  }

  private async sendMessage(event) {
   
    let isSmsSent = false;

    if ((event.key === 'Enter' && event.shiftKey) ||
        (event.key === 'Enter' && event.ctrlKey)) {
      return;
    }
    if (this.chatMessageLoading) {
      return;
    }
    if (event.key === 'Enter') {
      this.chatMessageText = this.chatMessageText.substring(0, event.target.selectionStart - 1) +
          this.chatMessageText.substring(event.target.selectionStart)
    }
    this.chatMessageLoading = true
    this.chatMessageText = this.chatMessageText.trim()
    if (!this.chatMessageText.length && !this.messageToForward) {
      this.chatMessageLoading = false
      return;
    }

    try {
      if (this.messageToReplay !== null) {
        await this.sendReplyMessage({
          replyTo: this.messageToReplay,
          text: this.chatMessageText
        });
        this.chatMessageText = '';
        this.chatMessageLoading = false
        this.messageToReplay = null;
        return;
      }
      if (this.messageToEdit !== null) {
        await this.editMessage({
          messageId: this.messageToEdit.id,
          text: this.chatMessageText
        });
        this.chatMessageText = '';
        this.chatMessageLoading = false
        this.messageToEdit = null;
        return;
      }
      if (this.messageToForward !== null) {
        await this.forwardMessage({
          messageToForward: this.messageToForward,
          text: this.chatMessageText
        });
        this.chatMessageText = '';
        this.chatMessageLoading = false
        chatStore.clearForwarding()
        return;
      }     

      // send sms
      if(this.isSMS){
        isSmsSent = await this.smsCall(false, '');
      }
      
      // add text in db
      if(!this.isSMS || isSmsSent){
        await this.sendTextMessage({
          text: this.chatMessageText, 
          isSMS: this.isSMS
        });
      } 

      this.chatMessageText = '';
      this.chatMessageLoading = false
    } catch (error: any) {
      this.showIssue(error.message);
      this.chatMessageLoading = false
    }

  }

  // send SMS
  private async smsCall(isMMS?: boolean, imageUrl?: string){

    try {
      console.log("sms phone:" + this.phoneNumber);
      this.loading = true;
      let isGroup = false;
      let phone_userId = '';

      // get phone numbers
      await this.getPhoneNumbers();
      if(this.phoneNumbers.length >= 1){
        phone_userId = this.phoneNumbers.join(',');
        isGroup = true;
      }
       

      // validation
      if(this.phoneNumber === '' && (!this.phoneNumbers || this.phoneNumbers.length === 0)){  
        console.error(constants.SMS_PHONE_UNKNOWN);       
        this.showIssue(constants.SMS_PHONE_UNKNOWN);
        this.loading = false;
        return false;
      } 
      
      // send sms          
      let smsResponse = await this.sendSMS({
        from: this.smsSenderId,
        to: this.phoneNumber,  // for single
        to_toId: phone_userId, // for multiple
        isGroup: isGroup, // for multiple
        text: this.chatMessageText,
        isFirstSMS: this.isFirstSMS,
        toId: this.customer.id,
        isChannel: false,
        isMMS: isMMS,
        imageUrl: imageUrl
      });

      // response
      if(smsResponse && smsResponse.errorCode === '0'){
        if(!isMMS){
          this.showInfo(constants.SMS_SUCCESS);
        } else{
          this.showInfo(constants.MMS_SUCCESS);
        }
        this.loading = false;
        return true
      }
      else{                        
        let errorMsg:string = !isMMS ? constants.SMS_FAILED : constants.MMS_FAILED;          
        if(smsResponse?.errorText){              
          errorMsg = smsResponse?.errorText;
        }
        
        //error alert
        console.error(errorMsg);
        this.showIssue(errorMsg);
      }

    } catch (e) {
      this.showIssue(constants.SMS_PHONE_INVALID);
      console.error("chat: Error in smsCall");
      console.error(e);
    }   

    this.loading = false;
    return false
  }

  private async onChangeChatType(isLoad: boolean = false){    

    // get phone numbers 
    if(this.isSMS){
      await this.getPhoneNumbers();
    }
    
    // validate 
    if(isLoad){
      this.isSMS = false
    } 
    else {
      if(this.smsSenderId === ''){  
        console.error(constants.SMS_SENDER_ID_INVALID);       
        this.showIssue(constants.SMS_SENDER_ID_INVALID);
        this.isSMS = false;      
      }
      else if(this.phoneNumber === '' && this.phoneNumbers?.length === 0){  
        console.error(constants.SMS_PHONE_UNKNOWN);       
        this.showIssue(constants.SMS_PHONE_UNKNOWN);
        this.isSMS = false;      
      }
      else if(this.phoneNumber.length < 10 && this.phoneNumbers?.length === 0){  
        console.error(constants.SMS_PHONE_INVALID);
        this.showIssue(constants.SMS_PHONE_INVALID);
        this.isSMS = false;      
      }
    }

    // toggle
    const el = document.querySelector('#chatType');
    if(el){
      el.classList.remove('chat-type-app');
      el.classList.remove('chat-type-sms');

      if(this.isSMS)
        el.classList.add('chat-type-sms');
      else
        el.classList.add('chat-type-app');
    }
  }

  private canDeleteForAll() {
    const isArray = Array.isArray(this.messageToDelete);
    if (isArray) {
      return !this.messageToDelete.find(
          item => item.sender.uid !== this.t2bUser!.id
      );
    } else {
      return (
          !!this.messageToDelete &&
          this.t2bUser!.id === this.messageToDelete.sender.uid
      );
    }
  }

  private onForwardCase() {
    this.titleForwardCaseDialog = 'Forward case';
    this.showForwardCaseDialog = true;
  }

  private onGroupCreate(event) {
    switch (event) {
      case 'create-group': {
        this.createGroup = true
        this.showAddChatMembersDialog = true
        break
      }
      case 'add-chat-member': {
        this.createGroup = false
        this.showAddChatMembersDialog = true
        break
      }
      default:
    }
  }

  private onForwardRequest() {
    this.titleForwardCaseDialog = 'Forward request';
    this.showForwardCaseDialog = true;
  }

  private onCaseForwarded() {
    this.closeConversation()
    this.showInfo('Case has been forwarded')
    this.$emit('request-forwarded')
  }

  private async requestAction(action) {
    this.disableRequestControls = true;
    await action();
    this.disableRequestControls = false;
  }

  private async onRejectRequest() {
    this.showUndo(
        this.selectedTextSession.id,
        'rejected',
        'The request is rejected.',
        {
          id: this.selectedTextSession.id,
          cId: this.selectedTextSession.case.id,
          bId: this.selectedTextSession.case.business.id
        },
        async (data) => {
          console.warn(data)
          const error = await this.rejectRequest({
                cId: data.cId,
                bId: data.bId
              }
          )
          if (!!error) {
            this.showIssue(error)
          } else {
            this.showInfo('Request has been rejected')
            if (this.$router.currentRoute.name === 'inbox-request'
                && this.$router.currentRoute.params.chatId === data.id) {
              await this.$router.push({name: 'inbox'})
            }
          }
        },
        5
    )
  }
  
  private async onRejectRequestasAdmin() {

    if(this.adminContacts.length > 0) {
        let firstContact = this.adminContacts[0]
        const data = {
            sender: this.sender,
            contactIds: [firstContact.id],
            title: this.groupChatTitle
        }
        if (data.contactIds.length > 0) {
          const added = await this.addChatMembers(data);
          if (!!added) {
            this.showUndo(
                this.selectedTextSession.id,
                'rejected',
                'The request is rejected.',
                {
                  id: this.selectedTextSession.id,
                  cId: this.selectedTextSession.case.id,
                  bId: this.selectedTextSession.case.business.id
                },
                async (data) => {
                  console.warn(data)
                  const error = await this.rejectRequest({
                        cId: data.cId,
                        bId: data.bId
                      }
                  )
                  if (!!error) {
                    this.showIssue(error)
                  } else {
                    this.showInfo('Request has been rejected')
                    if (this.$router.currentRoute.name === 'inbox-request'
                        && this.$router.currentRoute.params.chatId === data.id) {
                      await this.$router.push({name: 'inbox'})
                    }
                  }
                },
                5
            )
          }
        } else {
        }
    }
  }

  private async onAcceptRequest(directRequest: boolean) {
    this.disableRequestControls = true;
    this.acceptRequestSelected = true;
    const response = await this.acceptRequest();
    if (!!response.error) {
      this.showIssue(response.error);
    } else {
      this.showInfo('Request has been accepted');
      const {type, subtype} = this
      await requestsStore.clearRequestIdForType(`${type}:${subtype}`)

      if(!directRequest && this.adminContacts.length > 0) {
        let isMember = !!this.selectedTextSession?.memberIDs?.includes(this.userId)
        if(!isMember){
          let firstContact = this.adminContacts[0]
        const data = {
            sender: this.sender,
            contactIds: [firstContact.id],
            title: this.groupChatTitle
        }
        if (data.contactIds.length > 0) {
          const added = await this.addChatMembers(data);
          if (!!added) {
          }
        } else {
        }
        }
      }
      this.$emit('request-accepted', response.requestId);
    }
    this.acceptRequestSelected = false;
    this.disableRequestControls = false;
  }

  private async onBlockCustomer() {
    this.disableRequestControls = true;
    this.blockCustomerSelected = true;
    const error = await this.blockContact();
    if (!!error) {
      this.showIssue(error);
    }
    this.showInfo('Customer has been blocked');
    this.blockCustomerSelected = false;
    this.disableRequestControls = false;
  }

  private onUnblockUser() {
    this.dialogConfirmActionTitle = 'Do you want to unblock this user?';
    this.dialogConfirmActionMessage = '';
    this.onDialogConfirmActionPositiveCallback = async () => {
      this.dialogConfirmAction = false;
      const error = await this.unblockContact();
      this.setSelectedCustomer(null);
      if (!!error) {
        this.showIssue(error);
        return
      }
      this.showInfo('User has been unblocked');
    }
    this.confirm = 'Yes, unblock'
    this.dialogConfirmAction = true;
  }

  private async closeCase() {
    this.dialogConfirmAction = false;
    try {
      await this.caseClose();
      await chatStore.clearChatIdForType(this.key)
      this.showInfo('Case has been closed');
      await this.$router.push({name: 'active', params: {chatId: ''}, query: this.$route.query});
    } catch (err: any) {
      this.showIssue(err.message);
    }
  }

  private async onShareChat() {
    try {
      const shortLink = await chatStore.shareChat()
      await copyTextToClipboard(shortLink)
      this.showInfo('Chat link copied to clipboard', 'chain')
    } catch (err: any) {
      this.showIssue(err.message)
    }
  }

  private onCloseCase() {
    this.dialogConfirmActionTitle = 'Do you want to close this case?';
    this.dialogConfirmActionMessage =
        'This case will store in archive';
    this.onDialogConfirmActionPositiveCallback = this.closeCase;
    this.confirm = 'Yes, close'
    this.dialogConfirmAction = true;
  }

  private clearMessage() {
    this.chatMessageText = ''
    this.messageToEdit = null
    this.messageToReplay = null
    chatStore.clearForwarding()
  }

  private clearSelectedMessage() {
    this.messageToReplay = null
    this.messageToEdit = null
    this.chatMessageText = ''
    chatStore.clearForwarding()
  }

  private onChooseContact() {
    this.showDialogSendContact = true;
    // this.personalOnly = true;
    // this.contactSelectedCallback = this.onSendContact;
  }

  private async onSendContact(contact) {
    try {
      await this.sendContact({
        contact: contact, 
        isSMS: this.isSMS
      })
      this.showInfo('Contact has been sent')
    } catch (error: any) {
      this.showIssue(error.message)
    }
  }

  private onRequestPersonalData() {
    if (this.checkPermissionRequest) {
      if (!this.checkLastPermissionRequest) {
        this.requestPersonalData(this.selectedTextSession)
      }
    } else {
      this.onViewPersonalData()
    }
  }

  private onScheduleAppoint() {
    this.requestAppointment();
    //this.showDialogScheduleAnAppointment = true
  }

  private onChooseImage() {
    const fileField: HTMLInputElement = this.$refs.chooseImageForChat as HTMLInputElement
    fileField.value = ''
    fileField.click()
  }

  private onAttachFile() {
    if (this.isSMS){
      this.onChooseImage()
      return
    }
    const fileField: HTMLInputElement = this.$refs.chooseAttachmentForChat as HTMLInputElement
    fileField.value = ''
    fileField.click()
  }

  private closeLocation() {
    this.showMap = false
    this.location = null;
    this.resetSelectedLocation();
  }

  private messageTapped_TypeImage(message: any, forward: boolean) {
    this.imageTypeMessage = message;
    this.imageTypeMessageForward = forward
    this.showImage = true;
  }

  private closeImageDialog() {
    this.showImage = false
    this.imageTypeMessage = null;
  }

  private showLocation(geopoint: any) {
    console.log('showLocation => geopoint: ' + JSON.stringify(geopoint))
    this.location = {lat: geopoint.latitude, lng: geopoint.longitude};
    this.showMap = true;
  }

  private async sendLocation() {
    if (!!this.selectedLocation) {
      console.log('sendLocation => selectedLocation: ' + JSON.stringify(this.selectedLocation))
      await this.sendLocationMessage({
        isSMS: this.isSMS
      });
      this.closeLocation();
    }
  }

  private async onToggleFullConversation(full: boolean) {
    if (!full) {
      this.setFullConversation(false)
      return
    }

    if (full && this.firstMessageCreatedDate.toMillis() < this.caseOpenDate.toMillis()) {
      this.setFullConversation(true)
      return
    }

    if (this.unsubscribeMessages !== null) {
      this.unsubscribeMessages();
    }
    this.unsubscribeMessages = await this.loadMessages({chatId: this.chatId, full});
  }

  private onMessageInputChanged(event) {
    console.log(event)
  }

  private onViewContact(contact: any) {
    if (contact.type <= 2) {
      this.viewContactId = contact.id
    } else {
      this.viewCustomerId = contact.id
    }
  }

  private async init(chatId?: string, type?: string, subtype?: string) {
    const key = ['all', 'personal'].includes(subtype ? subtype : '') ? `${type}:${subtype}` : `${type}:personal`
    if (!chatId) {
      return
    }

    this.unsubscribeTextSession = await this.loadChat({chatId, type: key});

    this.unsubscribeMessages = await this.loadMessages({
      chatId,
      full: this.isInnerChat,
      isPersonal: subtype !== 'all'
    });

    this.unsubscribeCaseNotes = await chatStore.loadCaseNotes();

    if (this.isActiveChat) {
      this.unsubscribeCustomerNotes = await chatStore.loadCustomerNotes();
    }
  }

  private downloadImage(message) {
    const name = message.image.name
    const storageUri = `${message.textSessionId}/${message.id}/${name}`
    downloadFile({name, storageUri})
  }

  private async initAudioPlayer() {
    const player: any = this.$refs.audioPlayer;
    console.log('audioPlayer = ' + player);
    const audioPlayer: any = document.getElementById('audioPlayer');
    console.log('audioPlayer = ' + audioPlayer);
    const handleSuccess = function(stream) {
      if (window.URL) {
        player.srcObject = stream;
        console.log('handleSuccess => added player.srcObject');
      } else {
        player.src = stream;
        console.log('handleSuccess => added player.src');
      }
    };

    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({audio: true, video: false});
      const addTrackListener = (event) => {
        console.log(`onAddTrackListener => ${JSON.stringify(event)}`);
      };
      mediaStream.addEventListener('addtrack', addTrackListener);
      // mediaStream.removeEventListener('addtrack', addTrackListener)
      // handleSuccess(mediaStream);
      console.log('media stream setup');
    } catch (e) {
      console.error(e);
    }
  }

  unsubscribeAll() {
    if (this.unsubscribeTextSession) {
      try {
        this.unsubscribeTextSession();
      } catch (e) {
      }
      this.unsubscribeTextSession = null;
    }
    if (this.unsubscribeMessages) {
      try {
        this.unsubscribeMessages();
      } catch (e) {
      }
      this.unsubscribeMessages = null;
    }
    if (this.unsubscribeCaseNotes) {
      try {
        this.unsubscribeCaseNotes();
      } catch (e) {
      }
      this.unsubscribeCaseNotes = null;
    }
    if (this.unsubscribeCustomerNotes) {
      try {
        this.unsubscribeCustomerNotes();
      } catch (e) {
      }
      this.unsubscribeCustomerNotes = null;
    }
  }

  async onFocusIn() {
    if (!this.isUserPresent) {
      await chatStore.updatePresence({presentIn: this.chatId})
    }
  }

  async onFocusOut() {
    await chatStore.updatePresence({notPresentIn: this.chatId})
  }

  created() {
    this.$nextTick(() => {
      this.focusChatContainer()
    })
  }

  updated() {
    this.$nextTick(() => {
      this.isAdminPrevilege()
      this.focusChatContainer()
      this.switchRequestControl()
    })
  }

  focusChatContainer() {
    document.getElementById('chatContainer')?.focus()
  }

  async beforeRouteEnter(to, from, next) {
    await chatStore.updatePresence({presentIn: to.params.chatId})
    next((vm) => {
      vm.unsubscribeAll()
      vm.init(to.params.chatId, to.query.type, to.query.subtype)
    });
  }

  async beforeRouteUpdate(to, from, next) {
    this.onChangeChatType(true)
    this.isDecisionMadeForSMSWarning = false
    this.isDecisionMadeForAppWarning = false
    this.viewContactId = null
    this.viewCustomerId = null
    this.unsubscribeAll()
    // presence toggle
    await chatStore.updatePresence({presentIn: to.params.chatId, notPresentIn: from.params.chatId})
    await this.init(to.params.chatId, to.query.type, to.query.subtype)
    if (this.forwardTrigger) {
      this.clearMessage()
    } else {
      this.forwardTrigger = true
    }
    next();
  }

  async beforeRouteLeave(to, from, next) {
    this.viewContactId = null
    this.viewCustomerId = null
    this.unsubscribeAll()
    this.closeConversation();
    // presence toggle
    await chatStore.updatePresence({notPresentIn: from.params.chatId})
    next()
  }
  
  mounted(){
    //toggle chat type dropdown
    if(this.$route.name === 'active-chat'){
      this.chatTypeVisible = true;
    }
  }
  
}
